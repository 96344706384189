// Variable overrides first
$primary: #dd3333;

// Then import Bootstrap
@import "bootstrap/scss/bootstrap";
@import "./fonts.scss";
@import "./welcome.scss";

// RTL STYLES
.rtl {
  font-family: dana;
  direction: rtl;
}

.rtl p,
a,
div,
button,
input {
  font-weight: 500 !important;
  line-height: 1.85rem;
}

.rtl h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

body {
  background-color: #0d1117;
}

.logo-width {
  width: 70%;
}

.logo-animated {
  width: 50px;
}

.logo-type {
  width: 220px;
}

.logo-right-border {
  border-right: 1px solid #dd3333;
  height: auto;
}

.hero-image {
  background-image: url("../assets/jpg/bg-01.jpeg");
  background-color: #cccccc;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-blend-mode: multiply;
}

p,
li {
  text-align: justify;
  color: #a8acb1;
}

a {
  color: #dd3333;
  text-decoration: none;
}

.dropdown-item:active {
  /* color: var(--bs-dropdown-link-active-color); */
  text-decoration: none;
  background-color: #dd3333;
}

.dropdown-menu {
  transition: 1s all ease;
  min-width: 88px;
}

.showcard {
  transition: 1s all ease;
  animation: dropdownCard 1s;
  animation-fill-mode: forwards;
}

@keyframes dropdownCard {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card {
  background-color: #161b22d7 !important;
  border: 1px solid #30363d !important;
  // background-color: rgba(255, 255, 255, 0.4);
  // -webkit-backdrop-filter: blur(5px);
  // backdrop-filter: blur(5px);
}

input,
textarea {
  background-color: #161b22 !important;
  border: 1px solid #30363d !important;
  color: #afb9c4 !important;
}

.form-control {
  background-color: #161b22 !important;
}

.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #dd3333;
  outline: 0;
}

a:hover {
  color: #a52626;
  text-decoration: none;
}

.btn-primary {
  background-color: #dd3333 !important;
  border: 1px solid #dd3333 !important;
}

.btn-outline-primary {
  color: #a52626 !important;
  border: 1px solid #a52626 !important;
}

.btn-outline-primary:hover {
  color: white !important;
  background-color: #a52626 !important;
  border-color: #a52626 !important;
}

/* Layouts Styles */
.reveal {
  position: relative;
  transform: translateY(20%);
  opacity: 0;
  transition: 1s all ease;
}

.reveal.active {
  transform: translateY(0);
  opacity: 1;
}

.section-100 {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-40 {
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section-10 {
  min-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vertical-line {
  z-index: 1;
  width: 3px;
  background: #dd3333;
}

.vertical-line-gradient-s {
  background: linear-gradient(45deg, #dd3333, #dd3333, #dd3333, #0e112e);
  background-size: 100% 100%;
}

.vertical-line-gradient-e {
  background: linear-gradient(45deg, #2b1021, #dd3333);
  background-size: 100% 100%;
}

.h-100vh {
  height: 100vh;
}

.navbar {
  padding: 16px 0;
}

.nav-link {
  cursor: pointer;
}

.line-curved-top {
  -webkit-transform: scaleY();
  transform: scaleY();
}

.line-curved-bot {
  -webkit-transform: scaleX();
  transform: scaleX();
}

.line-curved-top-rtl {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.line-curved-bot-rtl {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.bg-icon {
  background-color: #dd3333;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  text-align: center;
  align-items: center;

  filter: blur(8px);
  -webkit-filter: blur(8px);
  z-index: -1;
}

.fade-in-image {
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.fade-out-image:hover {
  animation: fadeOut 1s;
}

@keyframes fadeOut {
  100% {
    opacity: 1;
  }
  0% {
    opacity: 0.5;
  }
}

.current {
  font-weight: 700;
}

.navbar-dark {
  background-color: #0d1117 !important;
}

.product-card {
  /* transition: transform .3s; */
  transition: 0.3s;
}

.product-card:hover {
  -ms-transform: scale(1.1); /* IE 9 */
  -webkit-transform: scale(1.1); /* Safari 3-8 */
  transform: scale(1.1);
  background-color: #dd3333 !important;
}

/* typing effect */
.fake-input {
  background-color: #161b22 !important;
  border: 1px solid #30363d !important;
  color: #afb9c4 !important;
}

.typing-container {
  display: flex;
  align-items: center;
}

.typing-container p {
  overflow: hidden;
  text-align: unset;
  margin: 0.5rem !important;
}

.typing-container p span.typed-text {
  color: #dd3333;
}

.typing-container p span.cursor {
  display: inline-block;
  background-color: #ccc;
  margin-left: 0.1rem;
  width: 3px;
  animation: blink 1s infinite;
}

.typing-container p span.cursor.typing {
  animation: none;
}

@keyframes blink {
  0% {
    background-color: #ccc;
  }
  49% {
    background-color: #ccc;
  }
  50% {
    background-color: transparent;
  }
  99% {
    background-color: transparent;
  }
  100% {
    background-color: #ccc;
  }
}

/* navbar */

.navbar-transparent {
  background-color: unset !important;
}

.navbar {
  transition: all 0.3s;
}

@media screen and (max-width: 992px) {
  .show-nav {
    background-color: #0d1117 !important;
  }
}

/* bg image */

#bg-img-01 {
  position: relative;
  /* width: 100%; */
  /* height: 200px; */
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  @media screen and (min-width: 768px) {
    height: 400px;
  }
}

#bg-img-02 {
  position: relative;
  /* width: 100%; */
  /* height: 200px; */
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  @media screen and (min-width: 768px) {
    height: 400px;
  }
}

.top-image-background {
  z-index: -1;
  top: 0px;
  background-image: url("../assets/jpg/aurora-bg.jpg");
}

.bottom-image-background {
  z-index: -1;
  bottom: 0px;
  background-image: url("../assets/jpg/bot-bg-img-01-mark.jpg");
}

.mid-image-background {
  z-index: -1;
  bottom: 0px;
  background-image: url("../assets/jpg/aurora-mid.jpg");
  background-size: cover;
  top: 10px;
}

@media screen and (max-width: 1400px) {
  .margin-from-top {
    margin-top: 5%;
  }
}

@media screen and (max-width: 1200px) {
  .margin-from-top {
    margin-top: 8%;
  }
}

@media screen and (max-width: 992px) {
  .margin-from-top {
    margin-top: 9%;
  }
}

@media screen and (max-width: 768px) {
  .margin-from-top {
    margin-top: 12%;
  }
}

@media screen and (max-width: 576px) {
  .margin-from-top {
    margin-top: 15%;
  }
}
@media screen and (max-width: 360px) {
  .margin-from-top {
    margin-top: 18%;
  }
}

.rtl {
  body {
    // font-family: IRANSans !important;
    // font-weight: 300;
    direction: rtl;
  }
  div,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  input,
  h1 {
    font-weight: bold;
  }
}

.btn-rtl-corner-end {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.input-rtl-corner-end {
  margin-right: -1px;
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.profile-img {
  width: 80px;
  height: auto;
}

.coloring-p1 {
  color: #dd3333;
}
.coloring-p2 {
  color: #dd338a;
}
.coloring-p3 {
  color: #dd8033;
}
.coloring-p4 {
  color: #425cb8;
}
