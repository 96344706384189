.welcome-card {
  border: 1px solid #525252;
}

.welcome-icon-lg {
  font-size: 6rem;
  color: #30363d;
}

.welcome-icon-sm {
  font-size: 2rem;
  color: #30363d;
}
